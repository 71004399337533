import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Code from "../components/code"
import person from "../images/svg/person-chat.svg"

const OldPlayground = ({ location }) => {

  return (

    <Layout>

      <Seo title="Playground" />
      {/* <div className="page-wrapper"> */}

      <div class="page-header">
          <div class="header-container">


            <div class="header-copy">
              <h1>Playground</h1>
              <p>Things that I’m trying out and can't do on CodePen</p>
            </div>
            <div class="header-image">
              <img src={person} alt="Person worried" style={{ width: "180px", margin: "0 auto" }} />
            </div>
          </div>
        </div>

    
      <div className="page-body">

        <h2>Whats the plan?</h2>
        <h2>Why</h2>

        <p>The act of writing and trying to describe a concept increases your understanding of the idea. By providing a space for these learning sit should become both a better way to udnderstand things as well as a form of long term memory</p>
        <h2>Who</h2>
        <p>For me, learning and growing. For people interested in similar ideas.</p>
        <h2>When &amp; Where</h2>
        <p>Anywhere on the web - responsive, live etc.</p>
        <h2>What</h2>
        <p></p>
        <h3>Prioritise</h3>
        <h3>Solve</h3>
        <h2>How (measure success)</h2>

        <ul>
          <li>one</li>
          <li>two</li>
          <li>three</li>
          <li>four</li>
        </ul>

        <Link to="/">Go back to the homepage</Link>
        <h2>The story so far...</h2>
        <ul>
          <li>install NVM - Node version manager</li>
          <li>install gatsby</li>
          <li>create graphCMS account (headless CMS)</li>
          <li>create blog starter in graphCMS</li>
          <li>connect graph data to gatsby</li>
          <li>setup rendering static pages from CMS data</li>
          <li>added SCSS rendering to gatsby (do I need it, should I be using module.css or styles in react?</li>
          <li>plan basic styling - typography at Gridlover.net</li>
          <li>select and add a Google font - good readbility</li>
          <li>learned how to run on local server <code>gatsby develop -H 0.0.0.0</code></li>
          <li>Added prism.js to style code on the page</li>
          <li>Basic styling</li>

          <li>Complete branding</li>
          <li>Light and darkmode</li>
          <li>Responsive</li>
          <li>Investigates darkmode - shoudl I be using css variables instead of scss variables... answer:Yes</li>
          <li>Read a post about BEM to functional CSS which changed my life</li>
          <li>Going to implement Tailwind.css? for all it's functional css benefits?</li>

          <li>TODO:</li>
          <li>Add Disqus for feedback</li>
          <li>Work out pagination</li>

          <li></li>
          <li></li>


        </ul>
        <Code code='<h1>Like this</h1>' language='html'></Code>
        <Code code="let name = 'Martin'; const Person = () =>{something}" language='javascript'></Code>
        <Code code='body{color:black;background-color:white;}' language="css"></Code>
      </div>
      {/* </div> */}
    </Layout>
  )
}
export default OldPlayground
